import React, {useState} from 'react';
import { MAIN_PAGE_TAB_LIST } from "../const/const";
import { useRouter } from 'next/router';
import { ChevronIcon } from '../appearance/icons/chevron_icon';
import { CarouselLine } from '../appearance/icons/carousel_line';
import { XilinxIcon } from '../appearance/icons/xilinx_icon';
import { ThorlabsIcon } from '../appearance/icons/thorlabs_icon';

const arrayOfDestributersBrandImagePath = [
    'distributor_1.png',
    'distributor_2.png',
    'distributor_3.png',
    'distributor_4.png',
    'distributor_5.png',
    'distributor_6.png',
    'distributor_7.png',
    'distributor_8.png',
    'distributor_9.png',
    'distributor_10.png',
    'distributor_11.png',
    'distributor_12.png',
    'distributor_13.png',
    'distributor_14.png',
    'distributor_15.png',
];

const arrayOfManufacturersBrandImagePath = [
    'manufacturer_1.jpg',
    'manufacturer_2.png',
    'manufacturer_3.png',
    'manufacturer_4.jpg',
    'manufacturer_1.jpg',
    'manufacturer_5.png',
    'manufacturer_6.png',
    'manufacturer_7.jpg',
    'manufacturer_8.png',
    'manufacturer_9.png',
    'manufacturer_10.jpg',
    'manufacturer_11.jpg',
    'manufacturer_12.png',
    'manufacturer_5.png',
    'manufacturer_13.png',
    
];

const getTitle = (activeTabIndex) => {
    return MAIN_PAGE_TAB_LIST[activeTabIndex].text;
};

const getContent = (activeTabIndex) => {
    return MAIN_PAGE_TAB_LIST[activeTabIndex].content;
};

export default function MainPage() {
    const [activeCarouselTab, setActiveCarouselTab] = useState(0);
    const handleChangeActiveTab = (changeValue, index=null) => {
        if(index!==null) {
            setActiveCarouselTab(index);
        } else {
            if(changeValue>0) {
                setActiveCarouselTab(activeCarouselTab===MAIN_PAGE_TAB_LIST.length-1?0:activeCarouselTab+1);
            } else {
                setActiveCarouselTab(activeCarouselTab===0?MAIN_PAGE_TAB_LIST.length-1:activeCarouselTab-1);
            }
        }
    };
    const router = useRouter();
    const handleClick = (e, url) => {
        e.preventDefault();
        router.push(url);
    };

    return (
        <main className="main">
            <article className="slider">
                <div className={`carousel-content carousel-content_${activeCarouselTab}`}>
                    {MAIN_PAGE_TAB_LIST.map((item, index) => (
                        <img key={item.number} className={`carousel-content__img ${activeCarouselTab === index ? 'carousel-content__img_active' : ''}`} src={`${item.img}`}></img>
                    ))}
                    <h2 className="carousel-content__title">{getTitle(activeCarouselTab)}</h2>
                    <p className="carousel-content__text">{getContent(activeCarouselTab)}</p>
                    <button className="button carousel-content__button">
                        <span className="button__text">Подробнее </span>
                        <ChevronIcon nameOfClass="button__icon" />
                    </button>
                    <div className="carousel-content__slider-icon">
                        <ChevronIcon nameOfClass="carousel-content__slider-button carousel-content__slider-button_left" handleChangeActiveTab={handleChangeActiveTab} step={-1}/>
                        <ChevronIcon nameOfClass="carousel-content__slider-button" handleChangeActiveTab={handleChangeActiveTab} step={1}/>
                    </div>
                </div>
                <ol className="carousel-list container">
                    {MAIN_PAGE_TAB_LIST.map((item, index) => {
                        return (
                            <li key={item.number} onClick={()=>handleChangeActiveTab('', index)} className="carousel-list__item ">
                                <CarouselLine nameOfClass={`carousel-list__item-line ${activeCarouselTab===index?'carousel-list__item-line_active':''}`} />
                                <span className="carousel-list__item-number">{item.number}</span>
                                <span className="carousel-list__item-text">{item.text}</span>
                            </li>
                        );
                    })}
                </ol>
            </article>
            <article className="destributers container">
                <h3 className="destributers__title">Дистрибьютеры</h3>
                <a className="link">
                    <span className="link__text">Посмотреть все </span>
                    <ChevronIcon nameOfClass="link__icon" />
                </a>
                <div className="brand-grid">
                    {arrayOfDestributersBrandImagePath.map(it => {
                        return (
                            <img key={it} className="brand-grid__img" src={`/pictures/${it}`} alt={it}/>
                        );
                    })}
                </div>
            </article>
            <article className="main-card">
                <div className="main-card-left">
                    <XilinxIcon />
                    <p className="main-card-left__content">Высоконадежные источники питания MEAN WELL</p>
                    <a className="main-card__link main-card__link-left">Подробнее
                        <ChevronIcon nameOfClass="main-card__link-svg" />
                    </a>
                </div>
                <div className="main-card-right">
                    <ThorlabsIcon />
                    <p className="main-card-right__content">Продукты электрической <br/>и оптической связи<br/>HUBER + SHUNER</p>
                    <a className="main-card__link main-card__link-right">Подробнее
                        <ChevronIcon nameOfClass="main-card__link-svg" />
                    </a>
                </div>
            </article>
            <article className="manufacturer container">
                <h3 className="manufacturer__title">Популярные производители</h3>
                <a className="link">
                    <span className="link__text">Посмотреть все </span>
                    <ChevronIcon nameOfClass="link__icon manufacturer-link__icon" />
                </a>
                <div className="brand-grid manufacturer-brand-grid">
                    {arrayOfManufacturersBrandImagePath.map((it, index) => {
                        return (
                            <img key={it+index} className="brand-grid__img" src={`/pictures/${it}`} alt={it}/>
                        );
                    })}
                </div>
            </article>
            <artice className="main-title container">
                <h1 className="main-title__text">Позвольте нам решить ваши вопросы по закупке электронных компонентов</h1>
                <button className="button main-title__button" onClick={(e)=>handleClick(e, '/login')}>
                    <span className="button__text">Вперед</span>
                    <ChevronIcon nameOfClass='button__icon'/>
                </button>
            </artice>
        </main>

    );
};