import Link from 'next/link';
import { useRouter } from 'next/router';
import { COMPANY_PAGE, CONTACTS_PAGE, DELIVERY_PAGE, MANUFACTURERS_PAGE, PRODUCTS_PAGE, TECHNICAL_DOCUMENTS_PAGE, USER_AGREEMENT_PAGE } from '../const/const';

export default function Footer() {
    const router = useRouter();
    const tab_name = router.pathname;
    return (
        <footer className="footer container">
            <ul className="footer-menu">
                <Link className={`footer-menu__item ${tab_name===COMPANY_PAGE?'footer-menu__item_active':''}`} href={COMPANY_PAGE}>О компании</Link>
                <Link className={`footer-menu__item ${tab_name===PRODUCTS_PAGE?'footer-menu__item_active':''}`} href={PRODUCTS_PAGE}>Каталог</Link>
                <Link className={`footer-menu__item ${tab_name===DELIVERY_PAGE?'footer-menu__item_active':''}`} href={DELIVERY_PAGE}>Способы доставки</Link>
                <Link className={`footer-menu__item ${tab_name===MANUFACTURERS_PAGE?'footer-menu__item_active':''}`} href={MANUFACTURERS_PAGE}>Производители</Link>
                <Link className={`footer-menu__item ${tab_name===CONTACTS_PAGE?'footer-menu__item_active':''}`} href={CONTACTS_PAGE}>Контакты</Link>
                <Link className={`footer-menu__item ${tab_name===TECHNICAL_DOCUMENTS_PAGE?'footer-menu__item_active':''}`} href={TECHNICAL_DOCUMENTS_PAGE}>Техническая документация</Link>
            </ul>
            <nav className="footer-navigation">
                <span className="footer-navigation__item">Copyright 2022. Aurora Electronics</span>
                <Link href="#" className="footer-navigation__item">Политика использования файлов COOKIE</Link>
                <Link 
                    href={USER_AGREEMENT_PAGE} 
                    className={`footer-navigation__item ${tab_name===USER_AGREEMENT_PAGE?'footer-navigation__item_active':''}`}>
                    Пользовательское соглашение
                </Link>
                <Link href="#" className="footer-navigation__item">Политика конфиденциальности</Link>
                <Link href="#" className="footer-navigation__item">Разработка:</Link>
            </nav>
        </footer>
    );
};